<section id="jobsbyprofessions">
	<div class="wrapper bg-light">
		<div class="container py-10 py-md-12">
			<!-- <div class="top-circle"><img class="w-100 d-lg-block d-none" src="../../../../../assets/img/docs/dots-BG.png"></div>
			<div class="bottom-arrow"><img class="w-100 d-sm-block d-none" src="../../../../../assets/img/docs/arrows.png"></div> -->
			<div class="row text-center mb-md-8 mb-6">
				<div class="col-xl-10 mx-auto" style="z-index: 110;">
					<h3 class="display-4"> Search Jobs by  <span class="underline-enokri">Profession</span> </h3> </div>
				<!-- /column -->
			</div>
			<!-- /.row -->
			<div class="row gy-md-4 gx-md-4 gx-lg-8 g-2 mb-3">
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/call center.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Call Center</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/driver.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Driver</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/cctv.svg" alt="" />
							<h6 class="mb-1 text-nowrap">CCTV Operator</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/security guard.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Security Officer</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/salesman.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Salesman</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/cook.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Cook/Cheif</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/receptionist.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Receptionist/Front Desk</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
				<div class="col-lg-3 col-md-4 col-6">
					<div class="card">
						<div class="card-body text-center p-2"> <img class="rounded-circle w-15 mb-4" src="../../../../../assets/img/svg/professions/delivery person.svg" alt="" />
							<h6 class="mb-1 text-nowrap">Delivery Rider</h6>
							<!-- <div class="meta mb-2">1000+ Jobs</div> -->
						</div>
						<!--/.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!-- /.col -->
			</div>
			<!--/.row -->
			<div class="row mt-md-12 mt-6">
				<div class="col-lg-6 col-xl-5 mx-auto text-center">
					<a [routerLink]="['/jobs']" class="btn btn-outline-blue btn-lg">View More Jobs</a>
					<!-- <a [routerLink]="['/professions-on-nokri']" class="btn btn-outline-blue btn-lg ms-1">View Professions</a> -->
          <!-- <a class="btn btn-outline-blue btn-lg me-1 mb-md-0 mb-2">Jobs By Professions</a> <a class="btn btn-outline-blue btn-lg ms-1">View All Jobs</a> -->
        </div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
	</div>
	<!-- /.container -->
</section>
<!-- /section -->