<section class="wrapper bg-light">
        <div class="container py-10">
        <!-- <div class="bottom-arrow"><img class="w-100 d-sm-block d-none" src="../../../../../assets/img/docs/arrows.png"></div> -->
       <div class="row py-6 align-items-center">
          <div class="col-xl-10 mx-auto">
             <h3 class="display-4 pb-0 text-center">What are you <span class="underline-enokri">looking for?</span> 
           </h3>
          </div>
       </div>
       <div class="row pb-6 align-items-center">
            
        <div class="col-lg-6">
            <div class="row align-items-center">
                <div class="col-6">
                    <div class="img-float-3"><img class="w-100" src="../../../../../assets/img/docs/find-worker.png"></div>
                </div>
                <div class="col-6">
                    <h3 class="display-4 pb-4 text-start">Verified <span class="underline-enokri blue">Workers</span> </h3>
                    <div class="d-flex">
                        <a class="shadow btn btn-blue ms-0" href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">Find workers</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row align-items-center">
                <div class="col-6">
                    <h3 class="display-4 pb-4 text-end">Verified <span class="underline-enokri">Jobs</span> </h3>
                    <div class="d-flex">
                        <a class="shadow btn btn-green  ms-auto" [routerLink]="['/user/jobs']">Find Jobs</a>
                    </div>
                </div>
                <div class="col-6">
                    <div class="img-float-3"><img class="w-100" src="../../../../../assets/img/docs/find-job.png"></div>
                </div>
            </div>
        </div>
       </div>
    </div>
</section>