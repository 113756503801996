<section id="searchjobs">
   <div class="wrapper bg-light">
         <div class="container py-10 py-md-12">
      <div class="row text-center pb-8">
         <div class="col-xl-10 mx-auto">
            <h3 class="display-4"> Recent
            <span class="underline-enokri">Jobs</span> on Nokri Platform
          </h3>
         </div> <!-- /column -->
      <!-- <div class="col-10 mx-auto text-center bg-sm mt-5 d-sm-none d-block"> <a [routerLink]="['/jobs']" class="btn btn-outline-blue btn-lg">View All Jobs</a> </div> -->
      </div> <!-- /.row -->
       <div class="row g-4 gx-md-10 gx-lg-8">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let item of jobsData | slice:0:6;">
            <div class="row">
                <div class="card shadow-lg">
                   <div class="card-body px-0 py-4">
                      <div class="row">
                         <div class="col-md-12 d-flex">
                            <div>
                              <span class="avatar bg-light text-white w-11 h-11 fs-20 me-4">
                              <img src="../../../../../assets/img/svg/professions/{{item.profession_icon}}" alt="">
                            </span>
                           </div>
                            <div class="d-md-flex w-100 bg-white align-items-center">
                               <h3 class="me-2 mb-1">{{item.profession}}</h3>
                              <span class="badge py-1 px-2 me-1" 
                              [ngClass]="{'bg-blue': item.type === 'Part-time', 'bg-green' : item.type === 'Full-time', 'bg-orange' : item.type === 'Short-task' }">
                                <i class="uil uil-clock me-1"></i> {{item.type}}
                              </span>
                              <!-- <span class="badge bg-red py-1 px-2"><i class="uil uil-fire me-1"></i>New</span> -->
                            </div>
                         </div>
                         <div class="offset-xl-1 offset-lg-2 offset-md-1 order-1 order-md-2">
                           <div class="col-xl-10 col-lg-10 col-md-10 ms-xl-5 ms-lg-0 ms-md-5 mt-md-auto mt-4">
                               <span class="text-body d-flex align-items-top mt-1 fs-16">
                                <i class="uil uil-location-point me-2"></i> {{item.address}}
                               </span>
                           </div>
                         </div>
                      </div>
                      <div class="row d-flex mt-3">
                        <div class="col-auto ms-auto">
                           <a class="shadow btn btn-outline-green btn-icon btn-icon-star ms-md-0 ms-auto me-2" (click)="viewDetail(item)"> <i class="uil uil-eye"></i> View Detail</a>
                        </div>
                      </div>
                   </div>
                </div>
             </div>
        </div>
        <!-- <div class="col-lg-6 col-12" *ngFor="let item of jobsData">
         <div class="row">
             <div class="card shadow-lg">
                <div class="card-body px-0 py-4">
                   <div class="row">
                      <div class="col-md-12 d-flex">
                         <div>
                           <span class="avatar bg-light text-white w-11 h-11 fs-20 me-4">
                           <img src="../../../../../assets/img/svg/professions/{{item.profession_icon}}" alt="">
                         </span>
                        </div>
                         <div class="d-md-flex w-100 bg-white align-items-center">
                            <h3 class="me-2 mb-1">{{item.profession}}</h3>
                           <span class="badge py-1 px-2 me-1" [ngClass]="{'bg-blue': item.type === 'Part-time', 'bg-green' : item.type === 'Full-time', 'bg-orange' : item.type === 'Short-task' }">
                             <i class="uil uil-clock me-1"></i> {{item.type}}
                           </span>
                           <span class="badge bg-red py-1 px-2"><i class="uil uil-fire me-1"></i>New</span>
                         </div>
                      </div>
                      <div class="offset-xl-1 offset-lg-2 offset-md-1 order-1 order-md-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 ms-xl-5 ms-lg-0 ms-md-5 mt-md-auto mt-4">
                           <ng-template [ngIf]="item.type === 'Short-task'" [ngIfElse]="notShortTask">
                              <div class="salary align-items-center mb-1"><i class="uil uil-social-distancing me-2"></i><strong> 5.4 Kilometers </strong> away from your location </div>
                            </ng-template>
                            <ng-template #notShortTask>
                              <div class="salary align-items-center mb-1"><i class="uil uil-money-bill me-2"></i><strong>{{item.salary}}</strong> per month</div>
                            </ng-template>
                            <span class="text-body d-flex align-items-top mt-1 fs-16">
                              <i class="uil uil-building me-2"></i> {{item.employer.name}}
                            </span>
                            <span class="text-body d-flex align-items-top mt-1 fs-16">
                              <i class="uil uil-calender me-2"></i> {{item.createAt}}
                             </span>
                            <span class="text-body d-flex align-items-top mt-1 fs-16">
                             <i class="uil uil-location-point me-2"></i> {{item.address}}
                            </span>
                        </div>
                      </div>
                   </div>
                   <div class="row d-flex mt-3">
                     <div class="col-auto ms-auto">
                        <a class="shadow btn btn-outline-green btn-icon btn-icon-star ms-md-0 ms-auto me-2" (click)="viewDetail(item)"> <i class="uil uil-eye"></i> View Detail</a>
                        <a class="shadow btn btn-green btn-icon btn-icon-star ms-md-0 ms-auto" (click)="sendApplication(item)"> <i class="uil uil-check"></i> Apply Now</a>
                     </div>
                   </div>
                </div>
             </div>
          </div>
     </div> -->
       </div> <!--/.row -->
       <div class="row mt-5">
         <div class="col-lg-6 col-xl-5 mx-auto text-center">
            <a [routerLink]="['/jobs']" class="btn btn-outline-blue btn-lg">View More Jobs</a>
         </div>
         <!-- /column -->
      </div>
      <!-- /.row -->
    </div>
   </div>
    <!-- /.container -->
 </section>