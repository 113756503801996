<footer class="bg-dark text-inverse">
  <div class="container py-13 py-md-15">
    <div class="row gy-6 gy-lg-0">
      <div class="col-md-4">
        <div class="widget">
          <img class="mb-4" src="./assets/img/logo-footer.png" srcset="./assets/img/logo-footer@2x.png 2x" alt="" />
          <p class="mb-4">© 2023-24 Nokri. <br class="d-none d-lg-block" />All rights reserved.</p>
          <!-- /.social -->
        </div>
        <!-- /.widget -->
      </div>
      <div class="col-md-4">
        <div class="widget">
          <h4 class="widget-title text-white mb-3">Contact Details</h4>
          <address class="pe-xl-15 pe-xxl-17">Office is located in Karachi, Pakistan</address>
          <a href="mailto:support@tapp.pk">support@tapp.pk</a><br /> +92 336 6123000
        </div>
        <!-- /.widget -->
      </div>
      <!-- /column -->
      <!-- /column -->
      <div class="col-md-4">
        <div class="widget">
          <h4 class="widget-title text-white mb-3">Learn More</h4>
          <ul class="list-unstyled  mb-0">
            <li class="nav-item"><a class="scroll" [routerLink]="['/home']" fragment="searchjobs">Search jobs</a></li>
            <li class="nav-item"><a class="scroll" [routerLink]="['/home']" fragment="howitworks">How it works</a></li>
            <li class="nav-item"><a class="scroll" [routerLink]="['/home']" fragment="jobsbyprofessions">Jobs by professions</a></li>
            <li class="nav-item"><a class="scroll" [routerLink]="['/home']" fragment="faq">FAQ</a></li>
            <li class="nav-item"><a class="scroll" [routerLink]="['/home']" fragment="contact">Contact</a></li>
          </ul>
        </div>
        <!-- /.widget -->
      </div>
      <!-- /column -->
    </div>
    <!--/.row -->
  </div>
  <!-- /.container -->
</footer>