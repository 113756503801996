import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthUserComponent } from './layout/auth-user/auth-user.component';
import { GuestUserComponent } from './layout/guest-user/guest-user.component';

const routes: Routes = [
  {
    path: '',
    component: GuestUserComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: '/',
      //   pathMatch: 'full'
      // },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(module => module.AboutModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(module => module.FaqModule)
      },
      {
        path: 'jobs',
        loadChildren: () => import('./pages/job-listings/job-listings.module').then(module => module.JobListingsModule)
      },
    ]
  },
  {
    path: 'user',
    component: AuthUserComponent,
    children: [
      {
        path: '',
        redirectTo: 'jobs',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(module => module.FaqModule)
      },
      {
        path: 'jobs',
        loadChildren: () => import('./pages/job-listings/job-listings.module').then(module => module.JobListingsModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
