<header class=" bg-white shadow-lg border-bottom" style="margin-bottom: 80px;">
    <nav class="navbar navbar-expand-lg bg-white" style="position: fixed;">
      <div class="container flex-lg-row flex-nowrap align-items-center bg-white">
        <div class="navbar-brand w-100">
          <a [routerLink]="['/home']">
            <img class="logo-dark" src="./assets/img/logo.png" srcset="./assets/img/logo@2x.png 2x" alt="" />
            <!-- <img class="logo-light" src="./assets/img/logo-light.png" srcset="./assets/img/logo-light@2x.png 2x" alt="" /> -->
          </a>
        </div>
        <div class="navbar-collapse offcanvas-nav me-lg-5">
          <div class="offcanvas-header d-lg-none d-xl-none ">
            <a [routerLink]="['/home']"><img src="./assets/img/logo-footer.png" srcset="./assets/img/logo-footer@2x.png 2x" alt="" /></a>
            <button type="button" class="btn-close btn-close-white offcanvas-close offcanvas-nav-close" aria-label="Close"></button>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item d-lg-none">
              <img class="avatar w-10" src="./assets/img/photos/user-logged-in.png" alt="user" /> 
              <span class="text-white"> 0312-3456789 </span>
              <hr class="m-0 my-2">
          </li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/about']">About us</a></li>
            <li class="nav-item"><a class="nav-link scroll" [routerLink]="['/home']"fragment="howitworks"> How it works</a></li>
            <li class="nav-item dropdown"><a class="nav-link scroll dropdown-toggle">More</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/home']"fragment="faq" >FAQ</a></li>
                <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/home']"fragment="contact">Contact</a></li>
              </ul>
            </li>
            <li class="nav-item d-lg-none"><a class="nav-link scroll" (click)="logout()">Logout</a></li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
        <div class="navbar-other responsive-width-100 d-flex ms-auto">
          <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- <ul class="navbar-nav flex-row align-items-center ms-auto d-flex responsive-width-100"> -->
            <li class="nav-item d-none d-lg-block">
                <img class="avatar w-10" src="./assets/img/photos/user-logged-in.png" alt="user" />
            </li>
            <li class="nav-item dropdown d-none d-lg-block">
              <a class="nav-link scroll dropdown-toggle">0312-3456789</a>
              <ul class="dropdown-menu">
                <li class="nav-item "><a class="dropdown-item cursor" (click)="logout()">logout</a></li>
              </ul>
            </li>
            <li class="nav-item d-lg-none">
              <div class="navbar-hamburger"><button class="hamburger animate plain" data-toggle="offcanvas-nav"><span></span></button></div>
            </li>
          </ul>
        </div>
        <!-- /.navbar-other -->
      </div>
      <!-- /.container -->
    </nav>
    <!-- /.navbar -->
  </header>
  <!-- /header -->