<section class="wrapper bg-white" #howitworks id="howitworks">
  <div class="container py-10 text-center">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs nav-tabs-basic d-inline-flex text-center">
          <li class="nav-item me-4">
            <a
              class="nav-link green active"
              data-bs-toggle="tab"
              href="#tab3-1"
            >
              <h3 class="display-4">How to get Job</h3>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab3-2">
              <h3 class="display-4">How to hire a Worker</h3>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- /.row -->
    <div class="tab-content mt-4">
      <div class="tab-pane fade show active" id="tab3-1">
        <!-- TAB FOR HOW TO FIND JOBS -->
        <div class="row gx-md-6 gy-6 isotope process-wrapper text-center">
          <!-- ROW AND PROCESS WRAPPER -->
          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">01</span></span
                >
                <p class="mb-0">Install the app from play store</p>
                <div class="text-center">
					<a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
                  <img
                    class="btn p-0 mt-1"
                    width="180px"
                    src="../../../../../assets/img/docs/get-the-app.png"
                  />
				</a>
                </div>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-1.png"
                  data-glightbox
                  data-gallery="shots-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-1.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">01</span></span
                >
                <p class="mb-0">Install the app from play store</p>
                <div class="text-center">
					<a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
                  <img
                    class="btn p-0 mt-1"
                    width="180px"
                    src="../../../../../assets/img/docs/get-the-app.png"
                  />
					</a>
                </div>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">02</span></span
                >
                <p class="mb-0">
                  Create your profile, add details in the app and proceed with
                  the verification
                </p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-2.png"
                  data-glightbox
                  data-gallery="shots-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-2.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">02</span></span
                >
                <p class="mb-0">
                  Create your profile, add details in the app and proceed with
                  the verification
                </p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">03</span></span
                >
                <p class="mb-0">Search for jobs and submit your applications</p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-3.png"
                  data-glightbox
                  data-gallery="shots-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-3.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">03</span></span
                >
                <p class="mb-0">Search for jobs and submit your applications</p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">04</span></span
                >
                <p class="mb-0">
                  The employer calls and hires you using your QR/CNIC
                </p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-4.png"
                  data-glightbox
                  data-gallery="shots-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-4.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-green disabled mb-3"
                  ><span class="text-white">04</span></span
                >
                <p class="mb-0">
                  The employer calls and hires you using your QR/CNIC
                </p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->
        </div>
        <!-- /.grid -->
      </div>
      <!-- /.tab -->
      <div class="tab-pane fade" id="tab3-2">
        <div class="row gx-md-6 gy-6 isotope process-wrapper text-center">
          <!-- ROW AND PROCESS WRAPPER -->
          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">01</span></span
                >
                <p class="mb-0">Install the app from play store</p>
                <div class="text-center">
					<a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
                  <img
                    class="btn p-0 mt-1"
                    width="180px"
                    src="../../../../../assets/img/docs/get-the-app.png"
                  />
					</a>
                </div>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-1.png"
                  data-glightbox
                  data-gallery="projects-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-1.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">01</span></span
                >
                <p class="mb-0">Install the app from play store</p>
                <div class="text-center">
					<a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
						<img
						class="btn p-0 mt-1"
						width="180px"
						src="../../../../../assets/img/docs/get-the-app.png"
					  />
					</a>
                </div>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">02</span></span
                >
                <p class="mb-0">
                  Create your profile add details on the app wait for it to be
                  approved/verified
                </p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-2.png"
                  data-glightbox
                  data-gallery="projects-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-2.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">02</span></span
                >
                <p class="mb-0">
                  Create your profile add details on the app wait for it to be
                  approved/verified
                </p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">03</span></span
                >
                <p class="mb-0">
                  Create a job posting in app or enokri.pk, add details such as
                  <br />
                  <span class="">
                    <span class="">- Profession/category <br /></span>
                    <span>- Location <br /></span>
                    <span>- Salary range (if want to disclose) <br /></span>
                    <span>- Job type (part-time/full-time) <br /></span>
                    <span>- Job description <br /></span>
                  </span>
                </p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-5.png"
                  data-glightbox
                  data-gallery="projects-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-5.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">03</span></span
                >
                <p class="mb-0">
                  Create a job posting in app or enokri.pk, add details such as
                  <br />
                  <span class="">
                    <span class="">- Profession/category <br /></span>
                    <span>- Location <br /></span>
                    <span>- Salary range (if want to disclose) <br /></span>
                    <span>- Job type (part-time/full-time) <br /></span>
                    <span>- Job description <br /></span>
                  </span>
                </p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->

          <!-- COLUMN TO SHOW EACH STEP OF A PROCESS -->
          <div class="project item col-md-6 col-lg-3 col-sm-6 col-8 mx-auto">
            <div class="grid grid-view projects-masonry">
              <!-- GRID TO CONTAIN A LOOP OF A IMAGES -->
              <!-- SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <div class="d-md-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">04</span></span
                >
                <p class="mb-0">
                  View worker's applications on your job post- contact them
                  directly and hire worker by their CNIC/QR code
                </p>
              </div>
              <!-- END | SHOW CONTENT AT TOP OF THE STEP IMAGE ONLY IN EXTRA SMALL AND SMALL -->
              <figure class="overlay overlay-1 rounded">
                <!-- IMAGE TO SHOW FOR STEP -->
                <a
                  href="../../../../../assets/img/photos/how-it-works-6.png"
                  data-glightbox
                  data-gallery="projects-group"
                >
                  <img
                    src="../../../../../assets/img/photos/how-it-works-6.png"
                    class="mb-4 d-block text-center mx-auto w-75"
                /></a>
              </figure>
              <!-- SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
              <div class="d-md-block d-none">
                <span class="icon btn btn-circle btn bg-blue disabled mb-3"
                  ><span class="text-white">04</span></span
                >
                <p class="mb-0">
                  View worker's applications on your job post- contact them
                  directly and hire worker by their CNIC/QR code
                </p>
              </div>
              <!-- END | SHOW CONTENT AT BOTTOM OF THE STEP IMAGE ONLY IN MEDIUM OR HIGHER SCREENS -->
            </div>
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
            <!-- END | GRID TO CONTAIN A LOOP OF A IMAGES -->
          </div>
          <!-- END | COLUMN  -->
        </div>
        <!-- /.grid -->
      </div>
    </div>
    <!-- /.tab-content -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->
