import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';

@Component({
  selector: 'app-send-application',
  templateUrl: './send-application.component.html',
  styleUrls: ['./send-application.component.scss']
})
export class SendApplicationComponent implements OnInit {
  constructor(public modal: NgbModal) {}

  ngOnInit(): void {}

  close() {this.modal.dismissAll();}

}
