import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobInterface } from 'src/app/interfaces/job-item';
import { DownloadAppComponent } from 'src/app/modals/download-app/download-app.component';
import { JobDetailComponent } from 'src/app/modals/job-detail/job-detail.component';
import { SendApplicationComponent } from 'src/app/modals/send-application/send-application.component';
import { JobsListingService } from 'src/app/services/jobs/job-listing';

@Component({
  selector: 'app-job-listings',
  templateUrl: './job-listings.component.html',
  styleUrls: ['./job-listings.component.scss']
})

export class JobListingsComponent implements OnInit {
  jobsData: JobInterface[] = [];

  constructor(private jobservice: JobsListingService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.jobservice.getJobs().subscribe(
      data => {
        data.forEach(element => {
          this.jobsData.push(element);
        });
      }, err => console.error('Observer got an error: ' + err),
      () => console.log('Observer got a complete notification', this.noOfJobs()));
  }

  searchJob = new FormGroup({
    city: new FormControl('', [Validators.required]),
    neighbourhood: new FormControl(''),
    profession: new FormControl('', [Validators.required]),
  });

  professions = [
    {   'id': '0', 'name': 'Carpenter'},
    {   'id': '1', 'name': 'Plumber'},
    {   'id': '2', 'name': 'AC Technician'},
    {   'id': '3', 'name': 'Driver'},
    {   'id': '4', 'name': 'Security Guard'},
  ]; 
  cities = [
    {   'id': '0', 'name': 'Karachi'},
    {   'id': '1', 'name': 'Lahore'},
  ];
  selected = [
    {   'id': '0', 'name': 'Karachi'}
  ];

  // sendApplication(item) {
  //   const modalRef = this.modal.open(SendApplicationComponent, {centered: true, backdrop: "static"});
  // }

  sendApplication(item) {
    const modalRef = this.modal.open(DownloadAppComponent, {centered: true, backdrop: "static"});
  }

  viewDetail(item) {
    const modalRef = this.modal.open(JobDetailComponent, { centered: true, size: 'lg', backdrop: "static", keyboard: false });
    modalRef.componentInstance.item = item;
  }

  noOfJobs() { return this.jobsData.length; }

}
