import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobInterface } from 'src/app/interfaces/job-item';
import { JobDetailComponent } from 'src/app/modals/job-detail/job-detail.component';
import { LoginPhoneComponent } from 'src/app/modals/login-phone/login-phone.component';
import { SendApplicationComponent } from 'src/app/modals/send-application/send-application.component';
import { JobsListingService } from 'src/app/services/jobs/job-listing';

@Component({
  selector: 'app-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss']
})

export class JobListingComponent implements OnInit {
  jobsData: JobInterface[] = [];

  constructor(private jobservice: JobsListingService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.jobservice.getJobs().subscribe(
      data => {
        data.forEach(element => {
          this.jobsData.push(element);
        });
      }, err => console.error('Observer got an error: ' + err),
      () => console.log('Observer got a complete notification', this.noOfJobs())
    );
  }

  noOfJobs() { return this.jobsData.length; }

  // sendApplication(item) {
  //   const modalRef = this.modal.open(SendApplicationComponent, {centered: true, backdrop: "static"});
  // }

  sendApplication(item) {
    const modalRef = this.modal.open(LoginPhoneComponent, {centered: true, backdrop: "static"});
  }

  viewDetail(item) {
    const modalRef = this.modal.open(JobDetailComponent, { centered: true, size: 'lg', backdrop: "static", keyboard: false });
    modalRef.componentInstance.item = item;
  }

}