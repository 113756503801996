<section class="wrapper bg-white">
      <div class="container py-14 py-md-12">
         <div class="row text-center py-6">
            <div class="col-xl-10 mx-auto">
              <h3 class="display-4">
                Reviews
             </h3>
            </div> <!-- /column -->
         </div> <!-- /.row -->
    <div class="position-relative">
      <div class="shape rounded-circle bg-soft-yellow rellax w-16 h-16" data-rellax-speed="1" style="bottom: 0.5rem; right: -1.7rem;"></div>
      <div class="shape bg-dot primary rellax w-16 h-16" data-rellax-speed="1" style="top: -1rem; left: -1.7rem;"></div>
      <div class="swiper-container dots-closer mb-6" data-margin="0" data-dots="true" data-items-xl="3" data-items-md="2" data-items-xs="1">
        <div class="carousel owl-carousel gap-small blog grid-view"
        data-margin="0" data-dots="true" data-autoplay="false" data-autoplay-timeout="5000" 
        data-responsive='{"0":{"items": "1"}, "768":{"items": "2"}, "992":{"items": "3"}, "1200":{"items": "3"}}'>
          <div class="item text-center">
            <div class="item-inner">
              <article>
                <div class="card">
                        <div class="card-body">
                          <blockquote class="icon mb-0">
                            <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.”</p>
                            <div class="blockquote-details">
                              <img class="rounded-circle w-12" src="./assets/img/avatars/te6.jpg" srcset="./assets/img/avatars/te6@2x.jpg 2x" alt="" />
                              <div class="info">
                                <h5 class="mb-1">Laura Widerski</h5>
                                <p class="mb-0">Sales Specialist</p>
                              </div>
                            </div>
                          </blockquote>
                        </div>
                        <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </article>
              <!-- /article -->
            </div>
            <!-- /.item-inner -->
          </div>
          <!-- /.item -->
          <div class="item text-center">
            <div class="item-inner">
              <article>
                <div class="card">
                  ...
                </div>
                <!-- /.card -->
              </article>
              <!-- /article -->
            </div>
            <!-- /.item-inner -->
          </div>
          <!-- /.item -->
          <div class="item text-center">
            <div class="item-inner">
              <article>
                <div class="card">
                  ...
                </div>
                <!-- /.card -->
              </article>
              <!-- /article -->
            </div>
            <!-- /.item-inner -->
          </div>
          <!-- /.item -->
          <div class="item text-center">
            <div class="item-inner">
              <article>
                <div class="card">
                  ...
                </div>
                <!-- /.card -->
              </article>
              <!-- /article -->
            </div>
            <!-- /.item-inner -->
          </div>
          <!-- /.item -->
        </div>
        <!-- /.owl-carousel -->


      </div>
      <!-- /.swiper-container -->
    </div>
    <!-- /.position-relative -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->

