<div class="modal-body px-4 pb-2 pt-3">
    <button class="btn-close btn-top" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
    <div class="row d-inline">
        <div class="col-md-12 text-center">
            <h5 class="modal-title">Enter 4 digit code</h5>
            <p>Code sent on your phone number</p>
        </div>
        <div class="col-sm-3 col-4 mx-auto my-md-5 my-3">
            <img class="img-fluid" src="../../../assets/img/svg/otp.svg" alt="profession" width="100%">
        </div>
        <form [formGroup]="OTPcheck" class="submit-form">
        <div class="col-md-10 col-12 mx-auto mt-md-5 mt-4">
            <div class="input-group d-flex p-0">
                <input formControlName="phone" class="form-control border border-2" style="width: 20%; height: 55px; margin-right: 5%;">
                <input formControlName="phone" class="form-control border border-2" style="width: 20%; height: 55px; margin-right: 5%;">
                <input formControlName="phone" class="form-control border border-2" style="width: 20%; height: 55px; margin-right: 5%;">
                <input formControlName="phone" class="form-control border border-2" style="width: 20%; height: 55px; margin-right: 5%;">
            </div>
        </div>
        <div class="col-md-10 col-12 mx-auto mt-md-5 mt-4">
            <a class="shadow btn btn-green btn-icon btn-icon-star w-100" (click)="otp()"> <i class="uil uil-check"></i> Submit</a>
        </div>
        </form>
    </div>
</div>