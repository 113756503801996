import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginOtpComponent } from '../login-otp/login-otp.component';

@Component({
  selector: 'app-login-phone',
  templateUrl: './login-phone.component.html',
  styleUrls: ['./login-phone.component.scss']
})
export class LoginPhoneComponent implements OnInit {

  constructor(public modal: NgbModal) {}

  ngOnInit(): void {}

  sendOTP = new FormGroup({ phone: new FormControl('', [Validators.required]) });

  close() {this.modal.dismissAll();}

  send() {
    this.modal.dismissAll();
    const modalRef = this.modal.open(LoginOtpComponent, { centered: true, backdrop: "static", keyboard: false});
  }

}