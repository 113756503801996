<section class="wrapper bg-green" id="contact">
  <div class="container py-10">
    <div class="row mt-5 align-items-top">
      <div class="col-md-6">
        <div class="row align-items-center">
          <h1 class="text-white counter mb-0 display-4">Contact Details</h1>
          <p class="text-white"> If you have any queries, feel free to contact us... </p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row ">
          <div class="col-1 pe-0">
            <img class="d-none d-md-block" [src]="'../../../../../assets/img/svg/static/email.svg'" width="75%" alt="" />
            <img class="d-md-none" [src]="'../../../../../assets/img/svg/static/email.svg'" width="50%" alt="" />
          </div>
          <div class="col-11 ps-0 d-inline-flex">
            <h6 class="text-white me-1" style="line-height: 2em;">E-Mail:</h6>
            <h3 class="text-white">support@tapp.pk</h3>
          </div>
          <br>
          <div class="col-1 text-end text-right float-right pe-0">
            <img class="d-none d-md-block" [src]="'../../../../../assets/img/svg/static/phone.svg'" width="75%" alt="" />
            <img class="d-md-none" [src]="'../../../../../assets/img/svg/static/phone.svg'" width="50%" alt="" />
          </div>
          <div class="col-11 ps-0 d-inline-flex">
            <h6 class="text-white me-1" style="line-height: 2em;">Phone:</h6>
            <h3 class="mb-0 text-white">+92 336 6123000</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>