<section #faq id="faq">
  <div class="wrapper bg-white">
    <div class="container py-10">
      <!-- <div class="bottom-arrow"><img class="w-100 d-sm-block d-none" src="../../../../../assets/img/docs/dots.png"></div> -->
      <div class="top-arrow"><img class="w-100 d-lg-block d-none" src="../../../../../assets/img/docs/q.png"></div>
      <div class="row text-center py-6">
        <div class="col-xl-10 mx-auto">
          <h3 class="display-4 pb-3" style="z-index: 120;">
            <span class="underline-enokri">FAQ</span> - Frequently Asked Questions
          </h3>
        </div> <!-- /column -->
      </div> <!-- /.row -->
      <div class="row ">
        <div class="col-12">
          <div id="accordion-1" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-1">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-1" aria-expanded="false"
                  aria-controls="accordion-collapse-6-1">What is NOKRI?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-1" class="collapse show" aria-labelledby="accordion-heading-6-1" data-bs-target="#accordion-1">
                <div class="card-body">
                  <p>Nokri( by TApp), where Verified Users can search for verified jobs or Employers can search and hire verified workers for free.
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div class="col-12">
          <div id="accordion-2" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-2">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-2" aria-expanded="false"
                  aria-controls="accordion-collapse-6-2">Is eNokri a free service?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-2" class="collapse " aria-labelledby="accordion-heading-6-2" data-bs-target="#accordion-2">
                <div class="card-body">
                  <p>Yes, it is an absolutely free service for both employers and workers.
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="col-12">
          <div id="accordion-3" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-3">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-3" aria-expanded="false"
                  aria-controls="accordion-collapse-6-3">How to search for verified jobs?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-3" class="collapse " aria-labelledby="accordion-heading-6-3" data-bs-target="#accordion-3">
                <div class="card-body">
                  <p>Download mobile app: <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">from here</a> <br>
                    Create a profile, after the admin approves your profile, you are able to send applications to posted jobs & employers will contact you.</p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="col-12">
          <div id="accordion-4" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-4">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-4" aria-expanded="false"
                  aria-controls="accordion-collapse-6-4">How to verify profile?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-4" class="collapse " aria-labelledby="accordion-heading-6-4" data-bs-target="#accordion-4">
                <div class="card-body">
                  <p>Download mobile app: <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">from here</a>
                    Create your profile (CNIC image, Face image and CNIC number), Admin will approve the profile. <br>
                    Verified profile is required for: job posing, verified job searching and verified workers search.
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div class="col-12">
          <div id="accordion-5" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-5">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-5" aria-expanded="false"
                  aria-controls="accordion-collapse-6-5">How do employers create a job post?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-5" class="collapse " aria-labelledby="accordion-heading-6-5" data-bs-target="#accordion-5">
                <div class="card-body">
                  <p>
                    1- Employers can create job posts from mobile app Download
                    <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">from here</a>
                    Or, signup on employer portal
                    <a href="https://spwt.tasdeeq.org" target="_blank">from here</a> <br>
                    2- Verify your profile details <br>
                    3- After profile approval, You can start posting jobs or hire and search for verified workers.
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="col-12">
          <div id="accordion-6" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-6">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-6" aria-expanded="false"
                  aria-controls="accordion-collapse-6-6">How do employers create a job post?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-6" class="collapse " aria-labelledby="accordion-heading-6-6" data-bs-target="#accordion-6">
                <div class="card-body">
                  <p>
                    1- Employers can create job posts from mobile app Download
                    <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">from here</a>
                    Or, signup on employer portal
                    <a href="https://spwt.tasdeeq.org" target="_blank">from here</a> <br>
                    2- Verify your profile details <br>
                    3- After profile approval, You can start posting jobs or hire and search for verified workers.
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="col-12">
          <div id="accordion-7" class="accordion-wrapper">
            <div class="card accordion-item">
              <div class="card-header" id="accordion-heading-6-7">
                <button class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-6-7" aria-expanded="false"
                  aria-controls="accordion-collapse-6-7">Which categories of jobs are available on NOKRI?</button>
              </div>
              <!-- /.card-header -->
              <div id="accordion-collapse-6-7" class="collapse " aria-labelledby="accordion-heading-6-7" data-bs-target="#accordion-7">
                <div class="card-body">
                  <p>
                    There are multiple categories: Driver, Carpenter, Plumber, Maid, Cook, Gardener, Teacher, Rider, Salesman, Security Guard, Factory staff, Machine
                    operator, Beautician, Hairdresser, Makeup artist, Gym Trainer, Tailor, Nurse, Painter, Call center agent, Barber, Receptionist, Mechanic, Data-entry,
                    Cleaners, Sweepers, House help, Electrician, Waiter, Hotel staff, Graphic designer, Manager, Supervisor, Photographer, Mobile repair, HR, Admin
                    person, Office boy, IT person, AC Technician, Accountant, Welder, Construction worker, Factory worker, and etc…
                  </p>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.collapse -->
            </div>
            <!-- /.card -->
          </div>
        </div>


      </div>
      <!-- /.row -->
      <div class="row mt-12">
        <!-- <div class="col-12col-xl-5 mx-auto text-center"> <a [routerLink]="['/faq']" class="btn btn-outline-blue btn-lg">Read More</a> </div> -->
        <!-- /column -->
      </div>
    </div>
    <!-- /.row -->
  </div>
</section>