import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GuestUserComponent } from './layout/guest-user/guest-user.component';
import { AuthUserComponent } from './layout/auth-user/auth-user.component';
import { NavbarComponent } from './layout/guest-user/navbar/navbar.component';
import { HomeModule } from './pages/home/home.module';
import { JobListingsModule } from './pages/job-listings/job-listings.module';
import { SendApplicationComponent } from './modals/send-application/send-application.component';
import { JobDetailComponent } from './modals/job-detail/job-detail.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPhoneComponent } from './modals/login-phone/login-phone.component';
import { LoginOtpComponent } from './modals/login-otp/login-otp.component';
import { DownloadAppComponent } from './modals/download-app/download-app.component';
import { NavbarLoggedComponent } from './layout/auth-user/navbar/navbar.component';

@NgModule({
  declarations: [
    AppComponent,
    GuestUserComponent,
    AuthUserComponent,
    NavbarComponent,
    NavbarLoggedComponent,
    SendApplicationComponent,
    JobDetailComponent,
    FooterComponent,
    LoginPhoneComponent,
    LoginOtpComponent,
    DownloadAppComponent
  ],
  exports:[
    NavbarComponent,
    NavbarLoggedComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HomeModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    JobListingsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
