<div class="modal-body px-4 pb-2 pt-3">
<button class="btn-close btn-top" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
<div class="row d-inline">
    <div class="col-md-12 text-center">
        <h5 class="modal-title">Want to send your application to this job?</h5>
    </div>
    <div class="col-sm-3 col-4 mx-auto my-md-5 my-3">
        <img class="img-fluid" src="../../../assets/img/svg/send.svg" alt="profession" width="100%">
    </div>
    <div class="col-md-10 col-12 mx-auto mt-md-5 mt-4">
        <a class="shadow btn btn-green btn-icon btn-icon-star w-100" href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
            <i class="uil uil-check"></i> Apply Now</a>
    </div>
    <div class="col-md-10 col-12 mx-auto mt-2">
        <a class="shadow btn btn-outline-green btn-icon btn-icon-star w-100" (click)="close()"> <i class="uil uil-times"></i> Not Now</a>
    </div>
</div>
</div>