<section class="wrapper bg-green">
    <div class="container pt-10">
      <div class="row align-items-center">
        <div class="col-md-6 img-wrapper">
          <div class="img-float-3"><img class="w-100" src="../../../../../assets/img/docs/CTA-mobile.png"></div>
        </div>
        <div class="col-md-6 text-md-start text-center">
          <h1 class="text-white counter mb-0" style="font-size: 4rem;">500,000+</h1>
          <h2 class="text-white display-2">Downloads</h2>
          <div class="row mt-5">
            <div class="col-md-6">
                  <img class="d-none d-md-block" [src]="'../../../../../assets/img/svg/static/star-rating-4.5.svg'" width="100%" alt=""> 
                  <img class="d-md-none" [src]="'../../../../../assets/img/svg/static/star-rating-4.5.svg'" width="45%" alt=""> 
              </div>
            <div class="col-md-12">
                <h2 class="mb-0 text-white">Download App & Search For Verified Jobs</h2>
            </div>
          </div>
          <div class="img-float-4 mt-2">
            <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
              <img class="btn w-50 p-0" style="width: 40% !important;" src="../../../../../assets/img/docs/google-btn-light.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
