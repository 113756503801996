import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendApplicationComponent } from '../send-application/send-application.component';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {

  @Input() public item;
  constructor(public modal: NgbModal) { }
  ngOnInit(): void {}

  close(){this.modal.dismissAll();}

  sendApplication(item) {
    this.modal.dismissAll();
    const modalRef = this.modal.open(SendApplicationComponent,
    {centered: true,
    backdrop: "static",
    keyboard: false});
  }

  copyID(item){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = item;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }
}
