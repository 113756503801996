import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HeroComponent } from './sections/hero/hero.component';
import { JobListingComponent } from './sections/job-listing/job-listing.component';
import { ProfessionsComponent } from './sections/professions/professions.component';
import { AboutComponent } from './sections/about/about.component';
import { HowItWorksComponent } from './sections/how-it-works/how-it-works.component';
import { TestimonialsComponent } from './sections/testimonials/testimonials.component';
import { FaqComponent } from './sections/faq/faq.component';
import { AppDownloadComponent } from './sections/app-download/app-download.component';
import { LookingForComponent } from './sections/looking-for/looking-for.component';
import { AppDownloadCtaComponent } from './sections/app-download-cta/app-download-cta.component';
import { ContactDetailsComponent } from './sections/contact-details/contact-details.component';

@NgModule({
  imports: [CommonModule, HomeRoutingModule],
  exports: [HomeComponent, HeroComponent, JobListingComponent, AboutComponent],
  declarations: [HomeComponent, HeroComponent, JobListingComponent, ProfessionsComponent, AboutComponent, HowItWorksComponent, TestimonialsComponent, FaqComponent, AppDownloadComponent, LookingForComponent, AppDownloadCtaComponent, ContactDetailsComponent]
})

export class HomeModule { }
