import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-user',
  templateUrl: './auth-user.component.html',
  styleUrls: ['./auth-user.component.scss']
})
export class AuthUserComponent implements OnInit {
  toggle = false;

  toggleSidebar(){
    this.toggle = !this.toggle;
    console.log(this.toggle);
  }
  constructor() { }

  ngOnInit(): void {
  }

}
