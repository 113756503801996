import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.scss']
})
export class LoginOtpComponent implements OnInit {

  constructor(public modal: NgbModal) {}

  ngOnInit(): void {}

  OTPcheck = new FormGroup({
    phone: new FormControl('', [Validators.required]),
  });
  
  close() {this.modal.dismissAll();}
  otp() {this.modal.dismissAll();}
  

}
