<div class="modal-body px-4 pb-2 pt-3">
    <button class="btn-close btn-top" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
    <div class="row d-inline">
        <div class="col-md-12 text-center">
            <h5 class="modal-title">Enter your phone number</h5>
        </div>
        <div class="col-sm-3 col-4 mx-auto my-md-5 my-3">
            <img class="img-fluid" src="../../../assets/img/svg/phone.svg" alt="profession" width="100%">
        </div>
        <form [formGroup]="sendOTP" class="submit-form">
        <div class="col-md-10 col-12 mx-auto mt-md-5 mt-4">
            <div class="input-group p-0 border border-2" style="border-radius: 4px;">
                <span class="input-group-text border-0" style="border-radius: 0px;">03</span>
                <input formControlName="phone" type="number" class="form-control border-0" maxlength="9" placeholder="Enter phone">
            </div>
        </div>
        <div class="col-md-10 col-12 mx-auto mt-md-5 mt-4">
            <a class="shadow btn btn-green btn-icon btn-icon-star w-100" (click)="send()"> <i class="uil uil-check"></i> Send Code</a>
        </div>
        </form>
    </div>
</div>