<section class="bg-light" style="padding-top: 100px;">
	<div class="wrapper">
		<div class="container pt-4 pt-md-10 pb-4 pb-lg-8 pb-md-3">
			<div class="img-dot"><img class="d-none d-md-block w-100" src="../../../assets/img/docs/dots-gradient.png"></div>
			<div class="img-dot-2"><img class="d-none d-md-block w-100" src="../../../assets/img/docs/dots.png"></div>
			<div class="row text-center py-md-6 py-2">
				<div class="col-xl-10 mx-auto">
					<h3 class="display-4 pb-2">
                        <span> View </span>
                        <span class="underline-enokri">Recent Jobs</span> on Nokri Platform  
                    </h3>
                </div>
			</div>
            <!-- <form [formGroup]="searchJob" novalidate class="submit-form">
                <div class="row d-flex">
                    <div class="offset-xl-1 col-xl-11 col-lg-12">
                        <div class="row align-items-xl-end align-items-center ">
                            <div class="col-xl-9">
                                <div class="row">
                                    <div class="col-md-4 mb-lg-0 mb-2">
                                        <div class="mb-2 mb-md-0">
                                            <label for="textInputExample" class="jobs-input-label mb-md-2 mb-1">Select Profession</label>
                                            <ng-select  class="jobs-input-style" [items]="professions" required  formControlName="profession" bindValue="id" bindLabel="name"></ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-lg-0 mb-2">
                                        <div class="mb-2 mb-md-0">
                                            <label for="textInputExample" class="jobs-input-label mb-md-2 mb-1">Select City </label>
                                            <ng-select  class="jobs-input-style" [items]="cities" required [(ngModel)]="selected" formControlName="city" bindValue="id" bindLabel="name"></ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-lg-0 mb-2">
                                        <div class="mb-2 mb-md-0">
                                            <label for="textInputExample" class="jobs-input-label mb-md-2 mb-1">Select Neighbourhood </label>
                                            <ng-select  class="jobs-input-style" [items]="" required [(ngModel)]="selected" formControlName="neighbourhood" bindValue="id" bindLabel="name"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 mt-md-2 mt-xl-auto d-flex">
                                <button class="btn btn-primary ms-xl-0 ms-md-auto btn-lg" style="margin-bottom: 2px;">Search Jobs</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form> -->
        </div>
        <div class="container pb-14 pb-md-12">
            <!-- <div class="row d-flex py-3 py-lg-4 py-md-3">
                <div class="col-auto ms-md-auto d-none d-md-block"> Showing <span class="badge bg-blue">4</span> Jobs </div>
            </div> -->
        <div class="row gy-6 gx-xl-12 gx-md-10">
         <div class="col-lg-6 col-12" *ngFor="let item of jobsData">
             <div class="row">
                 <div class="card shadow-lg">
                    <div class="card-body px-0 py-4">
                       <div class="row">
                          <div class="col-md-12 d-flex">
                             <div>
                               <span class="avatar bg-light text-white w-11 h-11 fs-20 me-4">
                               <img src="../../../../../assets/img/svg/professions/{{item.profession_icon}}" alt="">
                             </span>
                            </div>
                             <div class="d-md-flex w-100 bg-white align-items-center">
                                <h3 class="me-2 mb-1">{{item.profession}}</h3>
                               <span class="badge py-1 px-2 me-1" [ngClass]="{'bg-blue': item.type === 'Part-time', 'bg-green' : item.type === 'Full-time', 'bg-orange' : item.type === 'Short-task' }">
                                 <i class="uil uil-clock me-1"></i> {{item.type}}
                               </span>
                               <span class="badge bg-red py-1 px-2"><i class="uil uil-fire me-1"></i>New</span>
                             </div>
                          </div>
                          <div class="offset-xl-1 offset-lg-2 offset-md-1 order-1 order-md-2">
                            <div class="col-xl-10 col-lg-10 col-md-10 ms-xl-5 ms-lg-0 ms-md-5 mt-md-auto mt-4">
                               <ng-template [ngIf]="item.type === 'Short-task'" [ngIfElse]="notShortTask">
                                  <div class="salary align-items-center mb-1"><i class="uil uil-social-distancing me-2"></i>
                                    <!-- <strong> 5.4 Kilometers </strong> away from your location  -->
                                </div>
                                </ng-template>
                                <ng-template #notShortTask>
                                  <div class="salary align-items-center mb-1"><i class="uil uil-money-bill me-2"></i><strong>Rs. {{item.salary}}</strong> per month</div>
                                </ng-template>
                                <span class="text-body d-flex align-items-top mt-1 fs-16">
                                  <i class="uil uil-building me-2"></i> {{item.employer.name}}
                                </span>
                                <!-- <span class="text-body d-flex align-items-top mt-1 fs-16">
                                  <i class="uil uil-calender me-2"></i> {{item.createAt}}
                                 </span> -->
                                <span class="text-body d-flex align-items-top mt-1 fs-16">
                                 <i class="uil uil-location-point me-2"></i> {{item.address}}
                                </span>
                                <!-- <p class="mb-0 medium text-body mt-2 fs-16"> {{item.description}} </p> -->
                            </div>
                          </div>
                       </div>
                       <div class="row d-flex mt-3">
                         <div class="col-auto ms-auto">
                            <a class="shadow btn btn-outline-green btn-icon btn-icon-star ms-md-0 ms-auto me-2" (click)="viewDetail(item)"> <i class="uil uil-eye"></i> View Detail</a>
                            <a class="shadow btn btn-green btn-icon btn-icon-star ms-md-0 ms-auto" href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
                                <!-- (click)="sendApplication(item)"  -->
                                <i class="uil uil-check"></i> Apply Now</a>
                         </div>
                       </div>
                    </div>
                 </div>
              </div>
         </div> <!-- /column -->
        </div> <!--/.row -->
        <div class="row mt-12">
            <div class="col-lg-6 col-xl-5 mx-auto text-center">
                <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank" class="btn btn-outline-blue btn-lg">
                    View All Jobs</a> </div>
            <!-- /column -->
         </div>
     </div>
    </div>
     <!-- /.container -->
  </section>