import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar-logged',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarLoggedComponent implements OnInit {

  toggle =  false;
  toggleSidebar(){
    this.toggle = !this.toggle;
  }

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
  }

  logout(){
    console.log('testing');
  }

}
