<div class="modal-header">
    <h5 class="modal-title">Job Detail</h5>
    <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body p-md-5 p-3 pt-3 pb-0 pb-md-0">
  <article style="overflow-x: hidden">
    <div class="row">
        <div class="col-md-12 d-flex">
           <div class="">
             <span class="avatar bg-light text-white w-11 h-11 fs-20 me-4">
             <img src="../../../../../assets/img/svg/professions/{{item.profession_icon}}" alt="">
           </span>
          </div>
           <div class="d-md-flex w-100 bg-white align-items-center">
              <h3 class="me-2 mb-1">{{item.profession}}</h3>
             <span class="badge py-1 px-2 me-1" [ngClass]="{'bg-blue': item.type === 'Part-time', 'bg-green' : item.type === 'Full-time', 'bg-orange' : item.type === 'Short-task' }">
               <i class="uil uil-clock me-1"></i> {{item.type}}
             </span>
             <span class="badge bg-red py-1 px-2"><i class="uil uil-fire me-1"></i>New</span>
           </div>
        </div>
        <div class="offset-lg-1 offset-md-1">
          <div class="ms-lg-4 ms-md-2 mt-md-auto mt-4 col-lg-10 col-md-10">
             <ng-template [ngIf]="item.type === 'Short-task'" [ngIfElse]="notShortTask">
                <div class="salary align-items-center mb-1"><i class="uil uil-social-distancing me-2"></i><strong> 5.4 Kilometers </strong> away from your location </div>
              </ng-template>
              <ng-template #notShortTask>
                <div class="salary align-items-center mb-1"><i class="uil uil-money-bill me-2"></i><strong> RS. {{item.salary}}</strong> per month</div>
              </ng-template>
              <span class="text-body d-flex align-items-top mt-1 fs-16">
                <i class="uil uil-building me-2"></i> {{item.employer.name}}
              </span>
              <span class="text-body d-flex align-items-top mt-1 fs-16">
                <i class="uil uil-calender me-2"></i> {{item.createAt}}
               </span>
              <span class="text-body d-flex align-items-top mt-1 fs-16">
               <i class="uil uil-location-point me-2"></i> {{item.address}}
              </span>
              <ng-template [ngIf]="item.audio.isAvailable == true">
              <h5 class="mt-md-4 mt-3 mb-0">Audio</h5>
              <div class="col-md-12">
                <audio controls class="form-control border-0">
                  <source src="assets/audio/horse.ogv" type="audio/ogg">
                  <source src="assets/audio/{{item.audio.file}}" type="audio/mpeg"> Your browser does not support the audio element
                </audio>
              </div>
              </ng-template>
              <h5 class="mt-md-4 mt-3 mb-0">Description</h5>
              <p class="mb-0 medium text-body fs-16">{{item.description}}</p>
              <div class="mt-md-4 mt-3 align-items-center">
                <!-- <div class="col-lg-12 mb-4 w-100">
                  <a class="shadow-lg btn btn-green btn-icon-xlg btn-icon-star mx-auto mx-lg-0 w-100 w-lg-auto" (click)="sendApplication(item)"> <i class="uil uil-check"></i> Apply Now</a>
                </div> -->
              <div class="col-lg-6 col-md-12 mt-1 mt-lg-0">
              <h5 class="mb-1">Share the job with others</h5>
                <nav class="nav social align-items-center">
                  <a href="#" class="btn btn-circle btn-sm fs-16 my-1 btn-whatsapp">
                      <i class="uil uil-whatsapp"></i>
                  </a>
                  <a href="#" class="btn btn-circle btn-sm fs-16 my-1 btn-facebook">
                      <i class="uil uil-facebook-f"></i>
                  </a>
                  <div class="btn-group">
                    <button class="btn btn-secondary btn-sm disabled text-black">Job ID: {{item.jobID}}</button>
                    <button type="button" class="btn btn-outline-green btn-sm" (click)="copyID(item.jobID)" placement="top" ngbPopover="ID Copied!"> <i class="uil uil-file-copy-alt me-2"></i> Copy </button>
                  </div>
              </nav>
              </div>
            </div>
          </div>
        </div>
     </div>
    </article>
     <footer  class="d-inline">
      <div class="row py-4 bg-white my-auto">
        <div class="col-lg-10 offset-lg-1">
          <a class="shadow-lg btn btn-green btn-icon-xlg btn-icon-star w-100 w-lg-auto ms-lg-4"
          href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
          <!-- (click)="sendApplication(item)"  -->
          <i class="uil uil-check"></i> Apply Now</a>
        </div>
      </div>
    </footer>
</div>