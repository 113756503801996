import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})

export class HeroComponent implements OnInit {
  constructor() {}
  ngOnInit(){
    $(document).ready(function(){
    });
  }
}