<div class="modal-body px-4 pb-2 pt-3">
    <button class="btn-close btn-top" data-dismiss="modal" aria-label="Close" (click)="close()"></button>
    <div class="row d-inline">
        <div class="col-sm-6 col-md-8 mx-auto my-md-5 my-3">
            <img class="img-fluid" src="../../../assets/img/docs/download.png" width="100%" />
        </div>
        <div class="col-md-12 text-center">
            <h2 class="modal-title mb-0">Download TApp App</h2>
        </div>
        <div class="col-12 text-center">
            <div class="mt-2">
                <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
                    <img class="btn w-50 p-0" src="../../../assets/img/docs/get-the-app.png" />
                </a>
            </div>
        </div>
    </div>
</div>