import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { JobInterface } from 'src/app/interfaces/job-item';

@Injectable({providedIn: 'root'})

export class JobsListingService {

    constructor() { }

    getJobs(term: string = null): Observable<JobInterface[]> {
        let items = this.jobs();
        if (term) {
          items = items.filter(
            (x) => x.profession.toLocaleUpperCase().indexOf(term.toLocaleUpperCase()) > - 1
          );
        } return of(items).pipe(delay(500));
    }

    jobs(){
        return[
            {
            'jobID': 1,
            'profession': 'Call Center',
            'profession_icon': 'call center.svg',
            'type': 'Full-time',
            'salary': 30000,
            'distance': 0,
            'description': 'We are seeking highly motivated and customer-oriented individuals to join our dynamic call center team. As a Call Center Representative, you will handle inbound and outbound calls and address customer inquiries. Evening shift timings 4PM-12AM.',
            'address': 'Defence View 2, Karachi',
            'createAt': '2022-12-30 03:20:44',
            'employer': {
              'name': 'Private Office',
              'type': 'organization',
            },
            'audio': {
              'isAvailable': false,
              'file': 'lol.mp3',
            }
          },
            {'jobID': 2,
            'profession': 'Driver',
            'profession_icon': 'driver.svg',
            'type': 'Part-time',
            'salary': 20000,
            'distance': 0,
            'description': 'Part-time salesman needed. Required experience 6 months. Timings 11am-4pm. Location Tariq Road, Karachi.',
            'address': 'Karachi, Sindh',
            'createAt': '2022-06-30 10:20:44',
            'employer': {
              'name': 'ChaseUp',
              'type': 'organization',
            },
            'audio': {
              'isAvailable': false,
              'file': 'lol.mp3',
            }
          },
            {'jobID': 3,
            'profession': 'AC Mechanic',
            'profession_icon': 'ac repair.svg',
            'type': 'Full-time',
            'salary': 24000,
            'distance': 0,
            'description': 'Mujhe apni shop kay liye 4 saal expert AC mechanic ki need hai. Location Iqbal Town. 12 ghanty duty, din ka khana dukan ki taraf say.',
            'address': 'Faisalabad, Punjab',
            'createAt': '2022-06-15 6:30:44',
            'employer': {
              'name': 'Mohammad Ali',
              'type': 'individual',
            },
            'audio': {
              'isAvailable': false,
              'file': 'horse.mp3',
            }
          },
            {'jobID': 4,
            'profession': 'Office Boy / Helper',
            'profession_icon': 'office boy.svg',
            'type': 'Full-time',
            'salary': 22000,
            'distance': 5.4,
            'description': 'Hum office boy ki talash mein hain jo hamari company mein karkhanay aur daftar ki rozmarra ki zarooriyat ko pura kar sake. Office boy ke taur par aapko daftar mein safai ka bhi khayal rakhna hoga. Timings 9AM-8PM. Location: Shadman town',
            'address': 'Shadman town, Karachi',
            'createAt': '2022-06-30 10:20:44',
            'employer': {
              'name': 'Saad Kamran',
              'type': 'individual',
            },
            'audio': {
              'isAvailable': false,
              'file': 'horse.mp3',
            }
          },
          {'jobID': 5,
          'profession': 'Machine Operator',
          'profession_icon': 'machine operator.svg',
          'type': 'Full-time',
          'salary': 28000,
          'distance': 5.4,
          'description': 'یک ہنر مند اور قابل اعتماد مشین آپریٹر کی تلاش کر رہے ہیں۔ ایک مشین آپریٹر کے طور پر، آپ موثر پیداواری عمل کو یقینی بنانے کے لیے مختلف مشینوں کو ترتیب دینے، چلانے اور ان کی دیکھ بھال کے ذمہ دار ہوں گے۔ اوقات صبح 9 بجے سے شام 6 بجے تک',
          'address': 'SITE Korangi, Karachi',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Textile Company',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': true,
            'file': 'horse.mp3',
          }
        },
          {'jobID': 6,
          'profession': 'Data Entry',
          'profession_icon': 'data entry.svg',
          'type': 'Part-time',
          'salary': 25000,
          'distance': 5.4,
          'description': ' • You must be willing to put 100% effort • Have access to pc or laptop • Speak Hindi, English or Urdu.',
          'address': 'Remote Job - Lahore',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Upwork',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 7,
          'profession': 'Graphic Desinger for Printing',
          'profession_icon': 'graphic design.svg',
          'type': 'Part-time',
          'salary': 25000,
          'distance': 5.4,
          'description': 'Part time graphic designer ki zaroorat hai jo cards pamphlets aur posters design kar sakay. time 12pm-5pm. location Faisal Cantt',
          'address': 'Faisal Cantt, Karachi',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Mubarak Printing Shop',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 8,
          'profession': 'Security Guard/ Chawkidar',
          'profession_icon': 'security guard.svg',
          'type': 'Part-time',
          'salary': 22000,
          'distance': 5.4,
          'description': 'Personal security guard chahye ghar ke liye jo mahir aur qabil ho. Khana diya jayega. time 9am-9pm location Ittehad Town',
          'address': ' Ittehad Town, Karachi',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Jamshaid Bari',
            'type': 'individual',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 9,
          'profession': 'Computer Operator',
          'profession_icon': 'computer hardware repair.svg',
          'type': 'Part-time',
          'salary': 36000,
          'distance': 5.4,
          'description': 'We are seeking a highly skilled and organized Computer Operator to join our team. Responsibilities include monitoring computer systems and troubleshooting issues. Timings 9AM-5PM. Location Star Town',
          'address': ' Star Town, Lahore',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Private Job',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 10,
          'profession': 'Salesman',
          'profession_icon': 'salesman.svg',
          'type': 'Part-time',
          'salary': 33000,
          'distance': 5.4,
          'description': 'Experienced salesman required to sell beauty products to salons. Must have good communication skills and must be energetic. Timings 12PM-9PM. Location Cantonment',
          'address': 'Cantonment, Hyderabad',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Private Job',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 11,
          'profession': 'Receptionist',
          'profession_icon': 'receptionist.svg',
          'type': 'Part-time',
          'salary': 33000,
          'distance': 5.4,
          'description': 'Receptionist needed for part time duty in a clinic. Must follow the dress code and ethics. Timings 9AM-2PM. Location saddar Note: only for females.',
          'address': 'Saddar Town, Karachi',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Private Clinic',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          },
          {'jobID': 12,
          'profession': 'Rider/ Delivery Boy',
          'profession_icon': 'delivery person.svg',
          'type': 'Part-time',
          'salary': 30000,
          'distance': 5.4,
          'description': 'ہم ایک قابل اعتماد ڈیلیوری رائڈر کی خدمات حاصل کر رہے ہیں تاکہ موثر اور فوری آرڈر کی ڈیلیوری کو یقینی بنایا جا سکے، جس میں بہترین ڈیلیوری کی مہارت اور ٹریفک قوانین اور حفاظتی ضوابط پر عمل کرنے کا پختہ عزم ہے۔ کام کا وقت دوپہر 1 سے رات 10 تک ہے',
          'address': 'korangi, Karachi',
          'createAt': '2022-06-30 10:20:44',
          'employer': {
            'name': 'Private Clinic',
            'type': 'organization',
          },
          'audio': {
            'isAvailable': false,
            'file': 'horse.mp3',
          }
          }
        ];
    }
}