<app-hero></app-hero>
<app-about></app-about>
<app-download-cta></app-download-cta>
<app-job-listing></app-job-listing>
<app-how-it-works></app-how-it-works>
<app-professions></app-professions>
<app-download></app-download>
<app-looking-for></app-looking-for>
<!-- <app-testimonials></app-testimonials> -->
<app-faq></app-faq>
<contact-details></contact-details>
