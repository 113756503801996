import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DownloadAppComponent } from './modals/download-app/download-app.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'enokri';

  constructor(private config: NgSelectConfig, private router: Router, private modal: NgbModal) {
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    setTimeout(() => { this.onNoClick() }, 30000);  //30sec
    setTimeout(() => { this.onNoClick() }, 180000); //3min
    setTimeout(() => { this.onNoClick() }, 420000);  //7min
    setTimeout(() => { this.onNoClick() }, 600000);  //10min
  }
  onNoClick(): void {
    this.modal.dismissAll();
    this.modal.open(DownloadAppComponent, { centered: true, size: 'sm' });
  }
}
