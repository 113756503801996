import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginPhoneComponent } from 'src/app/modals/login-phone/login-phone.component';

declare const require: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('works') public info : ElementRef;

  toggle =  false;
  toggleSidebar(){
    this.toggle = !this.toggle;
  }

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
  }

  workerlogin(){
    console.log('testing');
    const modalRef = this.modal.open(LoginPhoneComponent, {centered: true});
  }


}
