<section class="wrapper bg-white">
    <div class="container py-10">
      <div class="row align-items-center">
        <div class="col-md-6 img-wrapper">
          <div class="img-float-3"><img class="w-100" src="../../../../../assets/img/docs/download.png"></div>
        </div>
        <div class="col-md-6 text-md-start text-center">
          <h1 class="display-1 mb-5" style="font-size: 2.5rem;">Download
            <span class="underline-enokri">TApp</span> 
          </h1>
          <div class="row mt-7">
            <div class="col-md-12">
              <div class="counter-wrapper">
                <h2 class="mb-0 counter" style="font-size: 2.5rem;">500,000+</h2>
                <h1 class=" display-1"></h1>
                <div class="text-body">
                  Downloads
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <span class="pb-5">
                <img class="d-none d-md-block" [src]="'../../../../../assets/img/svg/static/star-rating-4.5.svg'" width="100%" alt=""> 
                <img class="d-md-none" [src]="'../../../../../assets/img/svg/static/star-rating-4.5.svg'" width="45%" alt=""> 
              </span>
              <div class="text-body">
                4.7 out of 5 Stars
              </div>
            </div>
          </div>
          <div class="img-float-4 mt-5">
            <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
              <img class="btn w-50 p-0" style="width: 45% !important;" src="../../../../../assets/img/docs/get-the-app.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
