<section class="wrapper bg-white py-lg-0 py-10">
    <div class="container pb-lg-5">
      <div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center">
        <div class="col-lg-6">
          <h2 class="display-4 x-line mb-10 d-lg-none d-block text-center">
            <span class="underline-enokri"> Nokri</span> <span class="underline green"></span> Is Trusted By Pakistan
          </h2>
          <div class="bg-hero d-flex">
            <img class="bg-hero px-10 px-md-15 px-lg-10" src="../../../../../assets/img/docs/pakistan-map.png">
          </div>
        </div>
        <div class="col-lg-6 text-lg-start text-center">
          <h3 class="display-4 x-line mb-10 d-lg-block d-none">
            <span class="underline-enokri"> Nokri</span> <span class="underline green"></span> Is Trusted By Pakistan
          </h3>
          <div class="row gy-md-2">
            <div class="col-md-4 d-md-block d-flex ps-md-0 ps-10">
              <img src="../../../../../assets/img/svg/static/download.svg" class="icon-svg  text-green mb-3" alt="" />
              <div class="text-lg-start text-md-center text-start ps-md-0 ps-10">
                <h2 class="counter text-primary d-lg-block d-none">850,000+</h2>
                <h1 class="counter mb-0 text-primary d-block d-lg-none display-1">850,000+</h1>
                <p>Downloads</p>
              </div>
            </div>
            <div class="col-md-4 d-md-block d-flex ps-md-0 ps-10">
              <img src="../../../../../assets/img/svg/static/verified-workers.svg" class="icon-svg  text-primary mb-3" alt="" />
              <div class="text-lg-start text-md-center text-start ps-md-0 ps-10">
                <h2 class="counter text-primary d-lg-block d-none">220,000+</h2>
                <h1 class="counter mb-0 text-primary d-block d-lg-none display-1">220,000+</h1>
                <p>Verified Workers</p>
              </div>
            </div>
            <div class="col-md-4 d-md-block d-flex ps-md-0 ps-10">
              <img src="../../../../../assets/img/svg/static/verified-employers.svg" class="icon-svg  text-primary mb-3" alt="" />
              <div class="text-lg-start text-md-center text-start ps-md-0 ps-10">
                <h2 class="counter text-primary d-lg-block d-none">220,000+</h2>
                <h1 class="counter mb-0 text-primary d-block d-lg-none display-1">220,000+</h1>
                <p>Employers</p>
              </div>
            </div>
            <!-- <div class="col-12 mt-2 d-md-none">
              <h5 class="mt-5 mb-1">Downlaod the TApp App</h5>
              <div class="img-float-4"><img class="btn w-20 p-0" src="../../../../../assets/img/docs/get-the-app.png"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>