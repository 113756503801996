import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadAppComponent } from 'src/app/modals/download-app/download-app.component';
import { LoginPhoneComponent } from 'src/app/modals/login-phone/login-phone.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dialogRef: any;

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
  }

}
