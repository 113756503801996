<header class="border-bottom bg-white">
  <nav class="navbar navbar-expand-lg bg-white border-bottom border-2 py-lg-1" style="position: fixed;">
    <!-- <nav class="navbar navbar-expand-lg bg-white border-bottom border-2" style="position: fixed;"> -->
    <div class="container flex-lg-row flex-nowrap align-items-center bg-white">
      <div class="navbar-brand w-100">
        <a [routerLink]="['/']">
          <img class="logo-dark" src="./assets/img/logo.png" srcset="./assets/img/logo@2x.png 2x" alt="" />
        </a>
      </div>
      <div class="navbar-collapse offcanvas-nav">
        <div class="offcanvas-header d-lg-none d-xl-none">
          <a [routerLink]="['/']"><img src="./assets/img/logo-footer.png" srcset="./assets/img/logo-footer@2x.png 2x"
              alt="" /></a>
          <button type="button" class="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
            aria-label="Close"></button>
        </div>
        <ul class="navbar-nav">

          <li class="nav-item"><a class="nav-link scroll" [routerLink]="['/']" fragment="howitworks"> How It Works</a>
          </li>

          <li class="nav-item dropdown"><a class="nav-link scroll dropdown-toggle">Search Jobs</a>
            <ul class="dropdown-menu">
              <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/']" fragment="jobsbyprofessions">By
                  Professions</a></li>
              <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/jobs']">View All Jobs</a></li>
            </ul>
          </li>

          <li class="nav-item dropdown"><a class="nav-link scroll dropdown-toggle">About</a>
            <ul class="dropdown-menu">
              <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/about']">About Nokri</a></li>
              <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/']" fragment="faq">FAQ</a></li>
              <li class="nav-item"><a class="dropdown-item scroll" [routerLink]="['/']" fragment="contact">Contact Us</a></li>
            </ul>
          </li>

          <li class="nav-item d-md-none">
            <!-- <button (click)="workerlogin()" class="my-md-0 my-2 btn btn-sm btn-green shadow-lg w-12">For Worker</button> -->
            <!-- <button href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq" target="_blank"
              class="my-md-0 my-2 btn btn-sm btn-green shadow-lg w-12">For Workers</button> -->
              <button class="my-md-0 my-2 btn btn-sm btn-green shadow-lg w-12 text-white">
                <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank" style="color: white !important;">
                  For Workers</a>
              </button>
          </li>
          <li class="nav-item d-md-none">
            <a href="https://spwt.tasdeeq.org/" target="_blank" class="btn btn-sm btn-primary shadow-lg w-12">Employer Portal</a>
          </li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->
      <div class="navbar-other w-100 d-flex ms-auto">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item d-none d-md-block">
            <button class="my-md-0 my-2 btn btn-sm btn-green shadow-lg w-12 text-white">
              <a href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank" style="color: white !important;">
                For Workers</a>
            </button>
          </li>
          <li class="nav-item d-none d-md-block">
            <a href="https://spwt.tasdeeq.org/" target="_blank" class="btn btn-sm btn-primary shadow-lg w-12">Employer Portal</a>
          </li>
          <li class="nav-item d-lg-none">
            <div class="navbar-hamburger"><button class="hamburger animate plain" data-toggle="offcanvas-nav"><span></span></button></div>
          </li>
        </ul>
        <!-- /.navbar-nav -->
      </div>
      <!-- /.navbar-other -->
    </div>
    <!-- /.container -->
  </nav>
  <!-- /.navbar -->
</header>
<!-- /header -->