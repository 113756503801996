<section class="row d-flex bg-white hero-section align-items-center">
	<div class="col-lg-6 col-xl-7 my-auto text-lg-start text-center ps-lg-10 ps-xl-auto" style="padding-top: 20px;">
		<h1 class="display-1 mt-10 mt-lg-0 mb-sm-0 hero-heading ">
			<div class="text-lg-start text-center ps-xl-15 px-lg-0 px-md-10 px-sm-0 px-2">
				One Platform <span class="underline-enokri">Verified Jobs</span>
				and <span class="underline-enokri blue"> Verified Workers</span>
			</div>
		</h1>
		<div class="ps-xl-15 ps-lg-0 pt-md-2 pt-8 d-lg-flex d-inline-flex">
			<button class="btn px-md-10 py-md-4 shadow-lg btn-green btn-hero me-1" [routerLink]="['/jobs']"> Find Jobs </button>
			<a class="btn px-md-10 py-md-4 shadow-lg btn-blue btn-hero" href="https://play.google.com/store/apps/details?id=us.socol.tasdeeq&hl=en&gl=US" target="_blank">
				Hire Workers </a>
		</div>
	</div>
	<div class="col-lg-6 col-xl-5 d-none d-lg-block">
		<div class="bg-hero d-flex">
			<img class="mx-auto mt-auto bg-hero" src="../../../../../assets/img/docs/hero.png">
		</div>
	</div>
</section>